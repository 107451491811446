import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import { fetchQR } from "../../redux/constants/actions"
import { useAppSelector } from "../../redux/store"

import Dialog from "../../components/Dialog"

import LoaderSVG from "../../assets/icons/loader.svg"

import "./style.sass"

const QRCode = () => {
  const history = useHistory()

  const { constants, device } = useAppSelector((state) => ({
    constants: state.constants,
    device: state.device,
  }))

  const customButtons = constants?.customButtons || {}

  const [isFetching, setFetching] = useState<boolean>(false)
  const [isOpenDialog, setOpenDialog] = useState<boolean>(true)
  const [imageSrc, setImageSrc] = useState<string | null>(null)

  const handleCloseDialog = () => {
    setOpenDialog(false)
    history.push("/home")
  }

  useEffect(() => {
    const fetchQRImage = async () => {
      setFetching(true)
      try {
        const qrImageSrc = await fetchQR(
          device.UUID,
          customButtons.payload?.message,
        )
        if (qrImageSrc) {
          setImageSrc(qrImageSrc)
        }
      } catch (error) {
        console.error("Error fetching QR image:", error)
      } finally {
        setFetching(false)
      }
    }

    if (customButtons.payload?.message) {
      fetchQRImage()
    }
  }, [customButtons.payload, device.UUID])

  return (
    <Dialog
      open={isOpenDialog}
      setOpen={setOpenDialog}
      onClose={handleCloseDialog}
      title={customButtons.title}
      className="qrCodeDialog"
    >
      {isFetching ? (
        <div className="Loader">
          <LoaderSVG />
        </div>
      ) : imageSrc ? (
        <>
          <div className="qr">
            <img src={imageSrc} alt="QR Code" />
          </div>
          <div className="title">
            Scan code for <strong>{customButtons.title}</strong>
          </div>
        </>
      ) : (
        <div>No QR code available</div>
      )}
    </Dialog>
  )
}

export default QRCode
