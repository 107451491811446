import React from "react"

import { nameOrEmail } from "../../utils"
import InlineSVG from "../InlineSVG"

import { Person } from "../../redux/events/types"

import PersonSVG from "../../assets/icons/person.svg"

import "./style.sass"

type Props = {
  organizer: Person
  resource: string
  showIcon?: boolean
}

const Organizer = ({ organizer, resource, showIcon = true }: Props) => {
  if (organizer?.email !== resource) {
    return (
      <p className="Organizer">
        {showIcon && (
          <InlineSVG>
            <PersonSVG />
          </InlineSVG>
        )}{" "}
        {nameOrEmail(organizer)}
      </p>
    )
  }
}

export default Organizer
