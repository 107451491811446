import { AnyAction } from "redux"
import { v4 as uuidv4 } from "uuid"

import { filterOptimistic, getTimeNow } from "../utils"
import { events as eventFixtures, getRoomName } from "./_mockData/fixturesdyn2"
import { FETCH_CONSTANTS_SUCCESS } from "./constants/constants"
import {
  cancelMeetingStart,
  cancelMeetingSuccess,
  finishMeetingStart,
  finishMeetingSuccess,
  meetLaterStart,
  meetLaterSuccess,
  meetNowStart,
  meetNowSuccess,
} from "./events/actions"
import { FETCH_CALENDAR_SUCCESS, MEET_NOW_DURATION } from "./events/constants"
import { AppDispatch, RootState } from "./store"
import { getNextEventFromNow, getTime } from "@joan/joan-core"
import { t } from "@joan/joan-core"
import { DayJS } from "@joan/joan-core/dist/helpers/date.helpers"

const joan = { displayName: "Joan", email: "joan@visionect.com" }

export function fetchDemoConstants() {
  return {
    type: FETCH_CONSTANTS_SUCCESS,
    payload: {
      name: getRoomName(),
    },
  }
}

export function fetchDemoCalendar() {
  return {
    type: FETCH_CALENDAR_SUCCESS,
    payload: { events: eventFixtures },
  }
}

export const meetNowDemo =
  (meetNowDuration = MEET_NOW_DURATION) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      events: { events },
    } = getState()

    const start = getTimeNow()
    let end = start.add(meetNowDuration, "minute")
    const nextMeeting = getNextEventFromNow(filterOptimistic(events))

    if (nextMeeting) {
      const nextStart = getTime(nextMeeting.start)
      if (nextStart.isBefore(end)) {
        end = nextStart
      }
    }

    const summary = t("Occupied")

    const payload = { start, end, summary, organizer: joan }

    const id = uuidv4()

    dispatch(meetNowStart(payload))

    dispatch(meetNowSuccess({ id, ...payload }))
  }

const delay = (dispatch: AppDispatch) => (action: AnyAction) => {
  setTimeout(() => {
    dispatch(action)
  }, 333)
}

export const meetLaterDemo =
  (start: DayJS, end: DayJS, title: string) =>
  async (dispatch: AppDispatch) => {
    const payload = {
      start,
      end,
      summary: title,
      organizer: joan,
    }

    dispatch(meetLaterStart(payload))

    const id = uuidv4()

    delay(dispatch)(meetLaterSuccess({ id, ...payload }))
  }

export const finishMeetingDemo = (id: string) => (dispatch: AppDispatch) => {
  dispatch(finishMeetingStart(id))

  dispatch(finishMeetingSuccess({ id }))
}

export const cancelMeetingDemo = (id: string) => (dispatch: AppDispatch) => {
  dispatch(cancelMeetingStart(id))

  dispatch(cancelMeetingSuccess({ id }))
}
