import React, { useEffect, useRef } from "react"

import classNames from "classnames"

import Timestamp from "../Timestamp"

import MoreSVG from "../../assets/icons/more_horiz.svg"

import "./style.sass"

const GridSelect = ({
  items = [],
  value,
  onChange,
  onExpand,
  showExpander = false,
  isExpanded,
  isHorizontal,
  className,
  reduceFunc,
}) => {
  const surfaceRef = useRef(null)
  const selectedItemRef = useRef(null)
  const firstItemRef = useRef(null)

  useEffect(() => {
    if (isExpanded) {
      // Scroll to hide the first date label
      const firstItem = firstItemRef.current
      if (firstItem) {
        firstItem.scrollIntoView()
      }
    }
  }, [isExpanded])

  useEffect(() => {
    if (selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView()
    }
  }, [isHorizontal, value])

  const gridSelectClassName = classNames({
    GridSelect: true,
    [className]: !!className,
    isExpanded,
    isHorizontal,
  })

  return (
    <div className={gridSelectClassName}>
      <div className="surface" ref={surfaceRef}>
        {reduceFunc && isExpanded ? (
          items.reduce(reduceFunc, []).map((group, k) => (
            <React.Fragment key={`group-${group.value}`}>
              <h2 className="GroupTitle">{group.label}</h2>
              <ItemList
                items={group.items}
                currentValue={value}
                onChange={(value) => onChange && onChange(value)}
                forwardRef={(r, item, i) => {
                  if (item.value === value) {
                    selectedItemRef.current = r
                  }
                  if (k === 0 && i === 0) {
                    firstItemRef.current = r
                  }
                }}
              />
            </React.Fragment>
          ))
        ) : (
          <ItemList
            items={items}
            currentValue={value}
            onChange={(value) => onChange && onChange(value)}
            forwardRef={(r, item) => {
              if (item.value === value) {
                selectedItemRef.current = r
              }
            }}
          />
        )}
        {showExpander && (
          <div className="Item" onClick={onExpand}>
            <MoreSVG />
          </div>
        )}
      </div>
    </div>
  )
}

const ItemList = ({ items, currentValue, forwardRef, onChange }) =>
  items.map((item, i) => (
    <Item
      forwardRef={(r) => forwardRef(r, item, i)}
      key={`item-${i}-${item.value}`}
      item={item}
      isSelected={item.value === currentValue}
      isDisabled={item.isDisabled}
      onClick={() => onChange && onChange(item.value)}
    >
      {item.time ? <Timestamp time={item.time} /> : item.label}
    </Item>
  ))

const Item = ({
  children,
  item,
  isSelected,
  isDisabled,
  onClick,
  forwardRef,
}) => {
  const itemClassName = classNames({
    Item: true,
    isDisabled,
    isSelected,
  })
  const handleClick = !isDisabled && onClick ? () => onClick(item) : undefined
  return (
    <div className={itemClassName} onClick={handleClick} ref={forwardRef}>
      {children}
    </div>
  )
}

export default GridSelect
