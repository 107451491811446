import React, { Fragment } from "react"

import { AMENITIES } from "./utils"

import { RoomProperties } from "../../redux/constants/types"

import CameraSVG from "../../assets/icons/camera.svg"
import CameraAltSVG from "../../assets/icons/camera_alt.svg"
import ComputerSVG from "../../assets/icons/computer.svg"
import EditSVG from "../../assets/icons/edit.svg"
import PeopleSVG from "../../assets/icons/people.svg"
import PhoneSVG from "../../assets/icons/phone.svg"
import TVSVG from "../../assets/icons/tv.svg"

import "./style.sass"

type Props = {
  roomProperties: RoomProperties
}

const Amenities = ({ roomProperties }: Props) => {
  const { amenities = [], capacity = 0 } = roomProperties || {}
  const hasTV = amenities.find((a) => a.key === AMENITIES["TV"])
  const hasVideoConferencing = amenities.find(
    (a) => a.key === AMENITIES["Video conferencing"],
  )
  const hasPhone = amenities.find((a) => a.key === AMENITIES["Phone"])
  const hasComputer = amenities.find((a) => a.key === AMENITIES["Computer"])
  const hasWhiteboard = amenities.find((a) => a.key === AMENITIES["Whiteboard"])
  const hasProjector = amenities.find((a) => a.key === AMENITIES["Projector"])

  return (
    <div className="Amenities">
      {hasWhiteboard && <EditSVG className="whiteboard" />}
      {hasTV && <TVSVG className="tv" />}
      {hasPhone && <PhoneSVG className="phone" />}
      {hasComputer && <ComputerSVG className="computer" />}
      {hasVideoConferencing && <CameraAltSVG className="video-conferencing" />}
      {hasProjector && <CameraSVG className="projector" />}
      {!!capacity && (
        <Fragment>
          <PeopleSVG className="capacity" /> <span>{capacity}</span>
        </Fragment>
      )}
    </div>
  )
}

export default Amenities
