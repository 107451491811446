import React from "react"

import { useHistory, useLocation } from "react-router-dom"

import { PATHS } from "../../constants"
import Button from "../Button"
import { ACTIONS_TYPES } from "../Toolbar/constants"
import { Trans } from "@joan/joan-core"

import { useAppSelector } from "../../redux/store"

import BackSVG from "../../assets/icons/arrow_left.svg"

type Props = {
  className: string
}

const CustomButton = ({ className }: Props) => {
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location

  const isQRCode = pathname === PATHS.QR_CODE
  const isWebHook = pathname.includes(PATHS.WEB_HOOK)

  const { constants } = useAppSelector((state) => ({
    constants: state.constants,
  }))

  const { customButtons } = constants ?? {}
  const { title, action } = customButtons ?? {}

  const handleBack = () => history.push("/home")

  const handleQrCode = () => history.push(PATHS.QR_CODE)
  const handleWebhook = () => history.push(PATHS.WEB_HOOK)

  const handleMouseDown = () => {
    if (!Object.values(ACTIONS_TYPES).includes(action)) {
      return
    }

    if (action === ACTIONS_TYPES.QRCODE) {
      isQRCode ? handleBack() : handleQrCode()
    }

    if (action === ACTIONS_TYPES.WEBHOOK) {
      isWebHook ? handleBack() : handleWebhook()
    }
  }

  return (
    <Button onMouseDown={handleMouseDown} className={className}>
      <BackSVG />

      <span>
        <Trans>{title}</Trans>
      </span>
    </Button>
  )
}

export default CustomButton
