import React from "react"

import { Provider } from "react-redux"
import { v4 as uuidv4 } from "uuid"

import App from "./App"
import { getQueryParams } from "./utils"
import { createRoot } from "react-dom/client"

import configureStore from "./redux/store"

import "./index.sass"

const { isEmbedded, baseUnitSize } = getQueryParams(window.location.href)

const htmlEl = document.getElementsByTagName("html")[0]
if (!!htmlEl) {
  if (!!isEmbedded) {
    htmlEl.style.fontSize = baseUnitSize
      ? `${parseInt(baseUnitSize)}px`
      : `10px`
  } else {
    htmlEl.classList.add("responsive")
  }
}

const { localStorage } = window

let UUID = localStorage.getItem("UUID")

if (!UUID) {
  UUID = uuidv4()
  localStorage.setItem("UUID", UUID)
}

const initialState = { device: { UUID } }

const store = configureStore(initialState)

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
