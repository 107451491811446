import React, { Fragment, useMemo } from "react"

import classNames from "classnames"

import { ISODate } from "../../types/sharedTypes"
import { filterOptimistic, getTimeNow, orderByDate } from "../../utils"
import { formatTimeWithNumerals, getTime, t, Trans } from "@joan/joan-core"

import { EventResponse } from "../../redux/events/types"
import { useAppSelector } from "../../redux/store"

import Attendees from "../../components/Attendees"
import Organizer from "../../components/Organizer"
import Timestamp from "../../components/Timestamp"

import "./style.sass"

type DayGroup = {
  date: string
  events: EventResponse[]
}

function groupByDays(events: EventResponse[]) {
  return events.reduce((days: DayGroup[], newEvent: EventResponse) => {
    const date = formatTimeWithNumerals(
      getTime(newEvent.start),
      "ddd, D MMM YYYY",
    )
    const existingDay = days.find((d) => d.date === date)
    if (existingDay) {
      const updatedDay = {
        date: existingDay.date,
        events: [...existingDay.events, newEvent],
      }
      const index = days.indexOf(existingDay)
      return [
        ...days.slice(0, index),
        updatedDay,
        ...days.slice(index + 1, days.length - 1),
      ]
    } else {
      const newDay = {
        date,
        events: [newEvent],
      }
      return [...days, newDay]
    }
  }, [])
}

const Timetable = () => {
  const now = getTimeNow()

  const events = useAppSelector((state) =>
    orderByDate(filterOptimistic(state.events.events)),
  )

  const eventsByDay = useMemo(
    () => groupByDays(events.filter((ev) => getTime(ev.end).isAfter(now))),
    [events, now],
  )

  const getSummary = (summary: string, start: ISODate, end: ISODate) => {
    const startDate = getTime(start)
    const endDate = getTime(end)
    const isOccupied = now.isAfter(startDate) && now.isBefore(endDate)

    if (!summary) {
      return isOccupied ? t("Occupied") : t("Booked")
    }

    return summary
  }

  const timetableClassName = classNames({
    Timetable: true,
    Route: true,
  })

  return (
    <div className={timetableClassName}>
      <h3>
        <Trans>Timetable</Trans>
      </h3>

      {eventsByDay.length === 0 && (
        <div className={`no-events-info`}>
          <p>
            <Trans>No meetings scheduled for today.</Trans>
          </p>
        </div>
      )}

      {eventsByDay.map((day, i) => (
        <Fragment key={`day-${day.date}`}>
          <p className={`date ${i === 0 && "first"}`}>{day.date}</p>

          <div className="events">
            {day.events.map((ev, i) => (
              <div className="event" key={`ev-${i}-${ev.summary}`}>
                <div className="duration">
                  <Timestamp time={ev.start} leadingZero /> -{" "}
                  <Timestamp time={ev.end} leadingZero />
                </div>

                <div className="info">
                  <div className="summary">
                    {getSummary(ev.summary, ev.start, ev.end)}
                  </div>

                  {ev.organizer && (
                    <Organizer
                      organizer={ev.organizer}
                      resource={ev.resource}
                    />
                  )}

                  {ev.attendees &&
                    ev.attendees.filter((a) => a.mail !== ev.organizer.email)
                      .length > 0 && (
                      <Attendees>{ev.attendees.length}</Attendees>
                    )}
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  )
}

export default Timetable
