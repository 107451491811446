export const ICON_BUTTON_KEYFRAMES = [{ opacity: 0.5 }, { opacity: 1 }]

export const BUTTON_KEYFRAMES = [
  { background: "#2b303b" },
  { background: "#161a25" },
]

export const ANIMATION_OPTIONS = {
  duration: 400,
  iterations: 1,
  easing: "linear",
}
