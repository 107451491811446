import React, { ReactNode } from "react"

import "./style.sass"

type Props = {
  children: ReactNode
}

const Duration = ({ children }: Props) => <p className="Duration">{children}</p>

export default Duration
