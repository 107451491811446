import { setProDvxLedColorURL } from "../../api/urls"
import { AppDispatch, RootState } from "../store"
import {
  CHANGE_PRODVX_LED_ERROR,
  CHANGE_PRODVX_LED_START,
  CHANGE_PRODVX_LED_SUCCESS,
} from "./constants"
import { ProDvxLedColor } from "./types"

export const changeProdDvxLedStart = () => ({
  type: CHANGE_PRODVX_LED_START,
})

export const changeProdDvxLedSuccess = (color: ProDvxLedColor) => ({
  type: CHANGE_PRODVX_LED_SUCCESS,
  payload: color,
})

export const changeProdDvxLedError = (error: string) => ({
  type: CHANGE_PRODVX_LED_ERROR,
  payload: error,
})

export const changeProDvxLedColor =
  (color: ProDvxLedColor, token: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { ledColor } = getState().proDvx

    if (ledColor === color) {
      return
    }

    dispatch(changeProdDvxLedStart())

    try {
      const response = await fetch(setProDvxLedColorURL(color, token))

      if (response.ok) {
        dispatch(changeProdDvxLedSuccess(color))
      } else {
        const error = await response.text()
        dispatch(changeProdDvxLedError(error))
      }
    } catch (e) {
      if (
        typeof e === "object" &&
        e &&
        "message" in e &&
        typeof e.message === "string"
      ) {
        dispatch(changeProdDvxLedError(e.message))
      }
    }
  }
