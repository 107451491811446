import { formatEventTitleWithJoanTag, isInDevelopment } from "../utils"

export const HOST = import.meta.env.VITE_APP_HOST

export const PROTOCOL = !isInDevelopment()
  ? "HTTPS"
  : HOST === "joan-test.joan.app"
    ? "HTTPS"
    : "HTTP"

export const DEVICE_SIZE = -2

export const API_URL = `${PROTOCOL}://${HOST}`
export const API_URL_V2 = `${API_URL}/api/2.0`

export const loginURL = (UUID: string) => `${API_URL}/tablet/add/${UUID}/`

export const calendarURL = (UUID: string) => `${API_URL}/calendar/${UUID}/`

export const constantsURL = (UUID: string) => `${API_URL}/constants/${UUID}/`

export const constantsNewURL = (UUID: string) =>
  `${API_URL}/api/2.0/device/${UUID}/constants/`

export const bookURL = (
  UUID: string,
  start: string,
  end: string,
  title?: string,
) =>
  `${API_URL}/calendar/${UUID}/book/${start}/${end}/${
    title ? `?meetingName=${formatEventTitleWithJoanTag(title)}` : ``
  }`

export const finishMeetingURL = (UUID: string, id: string) =>
  `${API_URL}/calendar/${UUID}/finish/${id}/?noshow=false`

export const cancelMeetingURL = (UUID: string, id: string) =>
  `${API_URL}/calendar/${UUID}/cancel/${id}/?noshow=false`

export const AUTH_URL = `${API_URL}/devices/is-authorized/`

export const checkInURL = (UUID: string, id: string) =>
  `${API_URL_V2}/device/${UUID}/event/${id}/`

export const qrURL = (UUID: string) =>
  `${PROTOCOL}://${HOST}/api/2.0/device/${UUID}/custom-button/qr-code/`

export const webHookURL = (UUID: string) =>
  `${PROTOCOL}://${HOST}/uuid/${UUID}/custom-button/trigger/`

// the image path returned by old constants API endpoint
export const IMAGE_PATH = `/media/tmp/`

export const PRODVX_API_URL = "http://localhost:3535/v1"

export const setProDvxLedColorURL = (color: string, token: string) =>
  `${PRODVX_API_URL}/setAllLeds?lrgb=${color}&token=${token}`

export const getImageURL = (image: string) => `${API_URL}${image}`
