import React, { HTMLProps } from "react"

import classNames from "classnames"

import SyncSVG from "../../assets/icons/sync.svg"

import "./style.sass"

interface Props extends HTMLProps<HTMLDivElement> {
  isSmall?: boolean
}

const Loading = ({ isSmall = false, ...props }: Props) => {
  const loadingClassName = classNames({
    Loading: true,
    isSmall,
  })
  return (
    <div className={loadingClassName} {...props}>
      <SyncSVG style={{ width: "200px", height: "200px" }} />
    </div>
  )
}

export default Loading
