import { AnyAction } from "redux"

import {
  CHANGE_PRODVX_LED_ERROR,
  CHANGE_PRODVX_LED_START,
  CHANGE_PRODVX_LED_SUCCESS,
  PRODVX_LED_COLORS,
} from "./constants"
import { ProDvxState } from "./types"

const defaultState = {
  ledColor: PRODVX_LED_COLORS.OFF,
  status: "success",
  error: null,
} as const

const proDvxReducer = (
  state: ProDvxState = defaultState,
  action: AnyAction,
) => {
  const { type, payload } = action

  switch (type) {
    case CHANGE_PRODVX_LED_START:
      return {
        ...state,
        status: "loading",
      }
    case CHANGE_PRODVX_LED_SUCCESS:
      return {
        ...state,
        ledColor: payload,
        status: "success",
      }
    case CHANGE_PRODVX_LED_ERROR:
      return {
        ...state,
        status: "error",
        error: payload,
      }
    default:
      return state
  }
}

export default proDvxReducer
