import React, { useEffect } from "react"

import { useCheckForFeatureFlag } from "../hooks/useCheckFeatureFlag"
import { isConfirmedEvent } from "../utils"

import { FEATURE_FLAGS } from "../redux/constants/constants"
import {
  selectFeatureset,
  selectProDvxToken,
} from "../redux/constants/selectors"
import {
  selectConfirmedEvents,
  selectCurrentEvent,
  selectIsLoadedEvents,
} from "../redux/events/selectors"
import { changeProDvxLedColor } from "../redux/proDvx/actions"
import { PRODVX_LED_COLORS } from "../redux/proDvx/constants"
import { useAppDispatch, useAppSelector } from "../redux/store"

const ProDvxProvider = () => {
  const isProDvxEnabled = useCheckForFeatureFlag(FEATURE_FLAGS.PRO_DVX)
  const proDvxToken = useAppSelector(selectProDvxToken)
  const isLoadedEvents = useAppSelector(selectIsLoadedEvents)
  return (
    <div>
      {isProDvxEnabled && !!proDvxToken && isLoadedEvents && (
        <ProDvxActions proDvxToken={proDvxToken} />
      )}
    </div>
  )
}

type ProDvxActionsProps = {
  proDvxToken: string
}

const ProDvxActions = ({ proDvxToken }: ProDvxActionsProps) => {
  const dispatch = useAppDispatch()
  const currentEvent = useAppSelector(selectCurrentEvent)
  const confirmedEvents = useAppSelector(selectConfirmedEvents)
  const featureset = useAppSelector(selectFeatureset)
  const checkInto = featureset?.checkinto ?? false
  const eventId = currentEvent?.id
  const isCheckedIn = isConfirmedEvent(eventId, confirmedEvents)

  useEffect(() => {
    dispatch(
      changeProDvxLedColor(
        getLedColor(eventId, !!checkInto, isCheckedIn),
        proDvxToken,
      ),
    )
  }, [dispatch, eventId, proDvxToken, checkInto, isCheckedIn])

  return null
}

export default ProDvxProvider

/**
 * Determines the LED color based on the provided parameters.
 *
 * @param eventId - The ID of the event. Can be null.
 * @param checkInto - A boolean indicating whether checking into the event feature is enabled.
 * @param isCheckedIn - A boolean indicating whether the user is already checked into the event.
 * @returns The LED color based on the provided parameters.
 */
const getLedColor = (
  eventId: string | null,
  checkInto: boolean,
  isCheckedIn: boolean,
) => {
  if (!eventId) {
    return PRODVX_LED_COLORS.FREE
  }
  if (checkInto && !isCheckedIn) {
    return PRODVX_LED_COLORS.CHECK_IN
  }
  if (checkInto && isCheckedIn) {
    return PRODVX_LED_COLORS.BOOKED
  }
  return PRODVX_LED_COLORS.BOOKED
}
