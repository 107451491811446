import React, { Fragment, ReactNode, useEffect } from "react"

import { generateFetcher } from "./api/api"
import {
  RoomSelect as RoomSelectInstructions,
  SetupInstructions,
  Unpair as UnpairInstructions,
} from "./routes/Instructions"

import { fetchAuth } from "./redux/auth/actions"
import { fetchConstants } from "./redux/constants/actions"
import { fetchDemoCalendar, fetchDemoConstants } from "./redux/demoActions"
import { fetchCSRF } from "./redux/device/actions"
import { fetchCalendar } from "./redux/events/actions"
import { useAppDispatch, useAppSelector } from "./redux/store"

const IS_DEMO = !!import.meta.env.VITE_APP_IS_DEMO || false

const CSRFFetcher = generateFetcher(() => fetchCSRF())
const ConstantsFetcher = generateFetcher(() => fetchConstants())
const AuthFetcher = generateFetcher(() => fetchAuth())
const CalendarFetcher = generateFetcher(() => fetchCalendar())

type Props = {
  children: ReactNode
}

const Onboarding = ({ children }: Props) => {
  const dispatch = useAppDispatch()

  const constants = useAppSelector((state) => state.constants)
  const auth = useAppSelector((state) => state.auth)
  const csrftoken = useAppSelector((state) => state.device.csrftoken)

  useEffect(() => {
    if (IS_DEMO) {
      dispatch(fetchDemoConstants())
      dispatch(fetchDemoCalendar())
    }
  }, [dispatch])

  const { setup } = constants
  const { authenticated, pin, room, unpair } = auth

  if (IS_DEMO) {
    return <Fragment>{children}</Fragment>
  }

  if (!csrftoken) {
    return <CSRFFetcher isImmediate />
  } else if (Object.keys(constants).length === 0) {
    return <ConstantsFetcher isImmediate />
  } else if (Object.keys(auth).length === 0) {
    return <AuthFetcher isImmediate isImportant />
  } else if (unpair) {
    return (
      <Fragment>
        <UnpairInstructions />
        <AuthFetcher isImportant />
      </Fragment>
    )
  } else if (setup === true && authenticated === false) {
    return (
      <Fragment>
        <SetupInstructions />
        <AuthFetcher isImmediate={!pin} isPriority />
      </Fragment>
    )
  } else if (authenticated && room === false) {
    return (
      <Fragment>
        <RoomSelectInstructions />
        <AuthFetcher isPriority />
        <ConstantsFetcher />
      </Fragment>
    )
  } else if (room === true) {
    return (
      <Fragment>
        {children}
        <ConstantsFetcher isImmediate />
        <CalendarFetcher isImmediate isImportant />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <CalendarFetcher />
        <ConstantsFetcher isImportant />
        <AuthFetcher isImportant />
      </Fragment>
    )
  }
}

export default Onboarding
