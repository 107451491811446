import { AnyAction } from "redux"

import { MEET_LATER_ERROR, MEET_LATER_SUCCESS } from "../events/constants"
import { CONFIRM_ACTION, CONFIRM_ACTION_SUCCESS } from "./constants"

const initialUIState = { confirmAction: false }

const uiReducer = (state = initialUIState, action: AnyAction) => {
  const { type } = action

  switch (type) {
    case CONFIRM_ACTION:
      return {
        confirmAction: true,
      }
    case MEET_LATER_SUCCESS:
    case MEET_LATER_ERROR:
    case CONFIRM_ACTION_SUCCESS:
      return {
        confirmAction: false,
      }
    default:
      return state
  }
}

export default uiReducer
