import React, { useEffect, useState } from "react"

import classNames from "classnames"
import { useHistory, useLocation } from "react-router-dom"

import { PATHS } from "../../constants"
import Datestring from "../Datestring"
import Timestamp from "../Timestamp"

import { fetchConstants } from "../../redux/constants/actions"
import { fetchCalendar } from "../../redux/events/actions"
import { useAppDispatch, useAppSelector } from "../../redux/store"

import LogoSVG from "../../assets/joan-negative-horiz.svg"

import "./style.sass"

type Props = {
  showDate: boolean
  showTime: boolean
  logo?: string
}

const Header = ({ showDate, showTime, logo }: Props) => {
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location || {}
  const dispatch = useAppDispatch()
  const { logoObjectURL } = useAppSelector((state) => state.constants)

  const handleLogoClick = () => {
    dispatch(fetchCalendar())
    dispatch(fetchConstants())
    if (pathname !== PATHS.HOME) {
      history.push(PATHS.HOME)
    }
  }

  const pathClass = `path-${pathname.substring(1, pathname.length)}`

  const headerClassName = classNames({
    Header: true,
    [pathClass]: true,
  })

  return (
    <div className={headerClassName}>
      {!!logo && !!logoObjectURL ? (
        <img alt="logo" src={logoObjectURL} onClick={() => handleLogoClick()} />
      ) : (
        <LogoSVG onClick={() => handleLogoClick()} />
      )}
      <DateTime showDate={showDate} showTime={showTime} />
    </div>
  )
}

type DateTimeProps = {
  showDate: boolean
  showTime: boolean
}

const DateTime = ({ showDate, showTime }: DateTimeProps) => {
  const [time, setTime] = useState(new Date())
  useEffect(() => {
    const updateInterval = setInterval(() => {
      setTime(new Date())
    }, 1000)

    return () => clearInterval(updateInterval)
  }, [])

  return (
    <div className="date-time">
      {showDate && <Datestring className="date" date={time} />}
      {showTime && <Timestamp className="time" time={time} leadingZero />}
    </div>
  )
}
export default Header
