export const CHANGE_PRODVX_LED_START = "CHANGE_PRODVX_LED_START"
export const CHANGE_PRODVX_LED_SUCCESS = "CHANGE_PRODVX_LED_SUCCESS"
export const CHANGE_PRODVX_LED_ERROR = "CHANGE_PRODVX_LED_ERROR"

// ProDvx uses LRGB color format
export const PRODVX_LED_COLORS = {
  FREE: "0xFF00FF00", // Green
  BOOKED: "0xFFFF0000", // Red
  CHECK_IN: "0xFFFF8D00", // Orange but will be displayed as yellow as the device does not support orange
  OFF: "0x00000000", // Off
} as const
