import { AnyAction } from "redux"

import {
  FETCH_CALENDAR_ERROR,
  FETCH_CALENDAR_SUCCESS,
} from "../events/constants"
import {
  FETCH_CONSTANTS_ERROR,
  FETCH_CONSTANTS_PAYMENT_REQUIRED,
  FETCH_CONSTANTS_SUCCESS,
} from "./constants"

const constantsReducer = (state = {}, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_CONSTANTS_SUCCESS:
      const { warning_l2: warning_l2_dirty } = payload
      let warning_l2 = false

      if (typeof warning_l2_dirty === "string") {
        warning_l2 = warning_l2_dirty === "true"
      }

      return { ...payload, warning_l2 }

    // the warning_12 is used to show payment required message in the app
    case FETCH_CONSTANTS_PAYMENT_REQUIRED:
      return { ...state, warning_l2: true }

    case FETCH_CONSTANTS_ERROR:
    case FETCH_CALENDAR_ERROR:
      if (!payload.setup) {
        return state
      } else {
        return { setup: true }
      }

    case FETCH_CALENDAR_SUCCESS:
      if (!payload.setup) {
        return {
          ...state,
          setup: false,
        }
      } else {
        return state
      }

    default:
      return state
  }
}

export default constantsReducer
