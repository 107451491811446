import dayjs from "dayjs"

import { getTime } from "@joan/joan-core"
import { DayJS } from "@joan/joan-core/dist/helpers/date.helpers"

import { ExtendedEvent } from "./redux/events/types"
import { Person } from "./redux/events/types"

import { ACTIONS_TYPES, ActionTypes } from "./components/Toolbar/constants"

export function findCurrentMeeting(events: ExtendedEvent[], now: Date) {
  return events.find(
    ({ start, end }) =>
      getTime(start).isBefore(now) && getTime(end).isAfter(now),
  )
}

export const isConfirmedEvent = (
  eventId: string,
  confirmedEventsIds: string[],
) => {
  if (!eventId) {
    return false
  }

  return confirmedEventsIds.includes(eventId)
}

export function filterOptimistic(events: ExtendedEvent[]) {
  return events.filter(
    (ev) => !((ev.isFinishMeeting || ev.isCancelMeeting) && ev.isOptimistic),
  )
}

export const getTimeNow = () => dayjs()

export function orderByDate(events: ExtendedEvent[]) {
  return events.sort(dateCompare)
}

function dateCompare(a: ExtendedEvent, b: ExtendedEvent) {
  const startA = new Date(a.start)
  const startB = new Date(b.start)
  return startA.getTime() - startB.getTime()
}

export const zeroMinutes = (time: DayJS) => {
  return time.minute(0).second(0).millisecond(0)
}

export const isTakingPlaceOn = (event: ExtendedEvent, time: string) =>
  (getTime(event.start).isBefore(getTime(time)) ||
    getTime(event.start).isSame(getTime(time))) &&
  getTime(event.end).isAfter(getTime(time))

export const eventConflicts = (events: ExtendedEvent[], time: any) =>
  !!events.find((ev) => isTakingPlaceOn(ev, time))

export function isInDevelopment() {
  return import.meta.env.MODE === "development"
}

export function isIPad() {
  const userAgentMatch = navigator.userAgent.match(/iPad/i) !== null
  const { devicePixelRatio, innerWidth, innerHeight } = window
  const resolutionMatch =
    devicePixelRatio === 2 &&
    ((innerHeight === 1004 && innerWidth === 768) ||
      (innerHeight === 768 && innerWidth === 1004))

  return userAgentMatch || resolutionMatch
}

export function isPWAinBrowser() {
  let isInBrowser = true
  // replace standalone with fullscreen or minimal-ui according to your manifest
  if (matchMedia("(display-mode: standalone)").matches) {
    // Android and iOS 11.3+
    isInBrowser = false
  } else if ("standalone" in navigator) {
    // useful for iOS < 11.3
    isInBrowser = !navigator.standalone
  }
  return isInBrowser
}

export const nameOrEmail = (person: Person) =>
  person?.name || person?.displayName || person?.mail || person.email

export const pad = (num: number, size: number) => {
  var s = num + ""
  while (s.length < size) s = "0" + s
  return s
}

export const getQueryParams = (url: string): Record<string, string> => {
  const params: Record<string, string> = {}
  const parser = document.createElement("a")
  parser.href = url
  const query = parser.search.substring(1)
  const vars = query.split("&")

  vars.forEach((variable) => {
    const [key, value] = variable.split("=")
    if (key && value) {
      params[key] = decodeURIComponent(value)
    }
  })

  return params
}

export function hashCode(code: string) {
  var hash = 0
  if (code.length === 0) {
    return hash
  }
  for (var i = 0; i < code.length; i++) {
    var char = code.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return hash
}

// TODO: Implement skinny getText
export function translate(word: string) {
  return word
}

export async function retryFetchUntil<T>(
  URL: string,
  fetchOptions: RequestInit,
  options: { retryIn: number[] },
): Promise<T | Error> {
  const { retryIn } = options
  let count = 0

  async function retry(): Promise<T | Error> {
    try {
      const response = await fetch(URL, fetchOptions)

      if (response.ok) {
        try {
          const jsonResponse: T = await response.json()
          if ((jsonResponse as any).error) {
            throw new Error("Error in response")
          }
          return jsonResponse
        } catch {
          throw new Error("JSON parse error")
        }
      } else {
        throw new Error("HTTP error")
      }
    } catch {
      if (count < retryIn.length) {
        count++
        await new Promise<void>((resolve) =>
          setTimeout(resolve, retryIn[count - 1]),
        )
        return retry()
      }
      return new Error(`Retry failed ${retryIn.length} times.`)
    }
  }

  return retry()
}

export function orientationState() {
  return {
    isPortrait: window.innerHeight > window.innerWidth,
    isLandscape: window.innerHeight <= window.innerWidth,
  }
}

/**
 * Formats the event title for the calendar by appending a special tag `(Joan)`.
 * This tag indicates that the event was created using a Jot device.
 *
 * When the BE encounters an event with the `(Joan)` tag, it returns the event
 * with the `on_spot: true` flag.
 *
 * @param {string} title - The original title of the event.
 * @returns {string} The formatted event title with `(Joan)` appended.
 *
 * @example
 * const originalTitle = "Team Meeting";
 * const formattedTitle = formatEventTitleWithJoanTag(originalTitle);
 * console.log(formattedTitle); // "Team Meeting (Joan)"
 *
 * @remarks
 * The `(Joan)` tag is used to distinguish events created with a Jot device from those created by other means.
 * This allows the backend to properly handle the event in the context of the "Cancel meetings - only on the spot" option.
 * Events without the `(Joan)` tag will not be canceled on Jot devices when this option is enabled.
 */
export const formatEventTitleWithJoanTag = (title: string): string =>
  `${title} (Joan)`

/**
 * Replaces the path of an logo URL with a new path.
 *
 * @param logoURL - The new logo URL.
 * @param replacementPath - The new path to replace the new path with the original.
 * @returns The modified logo URL with the new path.
 *
 * @remoaarks
 * The new constant end-point returns the logo URLs with a different path
 * and that path doses not work with the current implementation of displaying the logo.
 */
export const replaceLogoUrlPath = (
  logoURL: string,
  replacementPath: string,
): string => {
  const imageName = logoURL.substring(logoURL.lastIndexOf("/") + 1)
  const newUrl = `${replacementPath}${imageName}`

  return newUrl
}

/**
 * Clamps a value between a minimum and maximum range.
 *
 * @param min - The minimum value of the range.
 * @param value - The value to be clamped.
 * @param max - The maximum value of the range.
 * @returns The clamped value.
 */
export const clamp = (min: number, value: number, max: number) => {
  return Math.min(Math.max(min, value), max)
}

export const isQRCodeCustomButton = (value: ActionTypes) =>
  ACTIONS_TYPES.QRCODE === value

export const isWebHookCustomButton = (value: ActionTypes) =>
  ACTIONS_TYPES.WEBHOOK === value
