import React, { Fragment, PureComponent } from "react"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

const FETCH_INTERVAL = import.meta.env.VITE_APP_FETCH_INTERVAL || 60
const IMPORTANT_FETCH_INTERVAL =
  import.meta.env.VITE_APP_IMPORTANT_FETCH_INTERVAL || 30
const PRIORITY_FETCH_INTERVAL =
  import.meta.env.VITE_APP_PRIORITY_FETCH_INTERVAL || 10

export function generateFetcher(fetchAction) {
  const mapAction = (dispatch) =>
    bindActionCreators(
      {
        performFetch: fetchAction,
      },
      dispatch,
    )

  class FetcherComponent extends PureComponent {
    constructor(props) {
      super(props)
      const { performFetch, isImmediate } = this.props
      isImmediate && performFetch()
    }

    componentDidMount() {
      const { performFetch, isImportant, isPriority } = this.props
      const intervalDuration = isImportant
        ? IMPORTANT_FETCH_INTERVAL
        : isPriority
          ? PRIORITY_FETCH_INTERVAL
          : FETCH_INTERVAL
      this.fetchInterval = setInterval(performFetch, intervalDuration * 1000)
    }

    componentWillUnmount() {
      this.fetchInterval && clearInterval(this.fetchInterval)
    }

    render() {
      return <Fragment />
    }
  }

  return connect(null, mapAction)(FetcherComponent)
}
