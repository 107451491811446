import { AnyAction } from "redux"

import { FETCH_CALENDAR_ERROR } from "../events/constants"
import { FETCH_AUTH_ERROR, FETCH_AUTH_SUCCESS } from "./constants"

const authReducer = (state = {}, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_AUTH_SUCCESS:
      return { ...payload, actionError: null }

    case FETCH_AUTH_ERROR:
      return { ...state, actionError: payload }

    case FETCH_CALENDAR_ERROR:
      if (payload.setup === true) {
        return {
          ...state,
          authenticated: false,
          pin: undefined,
        }
      } else {
        return state
      }

    default:
      return state
  }
}

export default authReducer
