import { AnyAction } from "redux"

export const makeLoadingReducer =
  (ACTION: string) =>
  (state = { isLoading: false }, action: AnyAction) => {
    switch (action.type) {
      case `${ACTION}_START`:
        return {
          isLoading: true,
        }

      case `${ACTION}_SUCCESS`:
      case `${ACTION}_ERROR`:
        return {
          isLoading: false,
        }

      default:
        return state
    }
  }
