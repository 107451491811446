export const fetchOptions: RequestInit = {
  method: "GET",
  mode: "cors",
  cache: "no-cache",
  credentials: "include",
  headers: {
    Accept: "application/json, text/javascript, */*; q=0.01",
    "Accept-Encoding": "gzip, deflate, br",
  },
}

export const signedFetchOptions = (csrftoken: string, body: any) => ({
  ...fetchOptions,
  method: "POST",
  headers: {
    ...fetchOptions.headers,
    "Content-Type": "application/x-www-form-urlencoded",
    "X-CSRFToken": csrftoken,
  },
  body,
})
