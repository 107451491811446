import React from "react"

import classNames from "classnames"

import { generateFetcher } from "../../api/api"
import { Trans } from "@joan/joan-core"

import { fetchConstants } from "../../redux/constants/actions"

import JoanSVG from "../../assets/joan-negative-horiz.svg"

import "./style.sass"

const ConstantsFetcher = generateFetcher(() => fetchConstants())

const PaymentNotification = () => {
  const paymentNotificationClassName = classNames("PaymentNotification")

  return (
    <div className={paymentNotificationClassName}>
      <JoanSVG />
      <Trans>Please visit your Joan Portal to resolve this issue!</Trans>
      <ConstantsFetcher />
    </div>
  )
}

export default PaymentNotification
