import { signedFetchOptions } from "../../api/fetch"
import { AUTH_URL, DEVICE_SIZE } from "../../api/urls"
import { AppDispatch, RootState } from "../store"
import {
  FETCH_AUTH_ERROR,
  FETCH_AUTH_START,
  FETCH_AUTH_SUCCESS,
} from "./constants"
import { AuthResponse } from "./types"

export const fetchAuthStart = (UUID: string) => ({
  type: FETCH_AUTH_START,
  payload: UUID,
})

export const fetchAuthSuccess = (auth: AuthResponse) => ({
  type: FETCH_AUTH_SUCCESS,
  payload: auth,
})

export const fetchAuthError = (error: any) => ({
  type: FETCH_AUTH_ERROR,
  payload: error,
})

export const fetchAuth =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID, csrftoken },
    } = getState()

    try {
      const body = `uuid=${UUID}&gtin=${0}&size=${DEVICE_SIZE}`

      dispatch(fetchAuthStart(UUID))
      const response = await fetch(
        AUTH_URL,
        signedFetchOptions(csrftoken, body),
      )

      if (response.status === 200) {
        const json = await response.json()
        dispatch(fetchAuthSuccess(json))
        return json
      } else {
        dispatch(fetchAuthError(response.body))
        return {}
      }
    } catch (e) {
      dispatch(fetchAuthError(e))

      return {}
    }
  }
