import React from "react"

import { Trans } from "@joan/joan-core"

import { useAppSelector } from "../../redux/store"

import JoanSVG from "../../assets/joan-negative-horiz.svg"

import "./style.sass"

export const PWA = () => (
  <div className="PWA Instructions Route">
    <p>
      <JoanSVG />
    </p>
    <p>
      <Trans>
        Please install the Joan on Tablets app by adding it to your Home Screen.
      </Trans>
    </p>
    <p>
      <a href="https://getjoan.com">
        <Trans>Instructions for Android & iOS.</Trans>
      </a>
    </p>
  </div>
)

export const RoomSelect = () => (
  <div className="RoomSelect Instructions Route">
    <JoanSVG />
    <p>
      <Trans>This device is successfully paired.</Trans>
      <br />
      <Trans>Please select which calendar to display in Joan Portal.</Trans>
    </p>
  </div>
)

export const Unpair = () => (
  <div className="Unpair Instructions Route">
    <JoanSVG />
    <p>
      <Trans>This device is out of sync with cloud.</Trans>
      <br />
      <Trans>Please unpair & re-pair the device in Joan Portal.</Trans>
    </p>
  </div>
)

export const SetupInstructions = () => {
  const pin = useAppSelector((state) => state.auth.pin)

  return (
    <div className="Setup Instructions Route">
      <JoanSVG />
      <p>
        <Trans>
          This tablet has not been paired yet. Please enter the following PIN
          when adding a device in Joan Portal, to pair this tablet to your
          account:
        </Trans>
      </p>
      <p className="pin">{!pin ? <span>&hellip;</span> : pin}</p>
    </div>
  )
}
