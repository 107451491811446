import React, { useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import { triggerWebhook } from "../../redux/constants/actions"
import { useAppSelector } from "../../redux/store"

import Dialog from "../../components/Dialog"

import CheckSVG from "../../assets/icons/check_circle.svg"
import ErrorSVG from "../../assets/icons/error_circle.svg"
import LoaderSVG from "../../assets/icons/loader.svg"

import "./style.sass"

const Webhook = () => {
  const history = useHistory()

  const { constants, device } = useAppSelector((state) => ({
    constants: state.constants,
    device: state.device,
  }))

  const { customButtons } = constants ?? null

  const [isFetching, setFetching] = useState<boolean>(false)
  const [isOpenDialog, setOpenDialog] = useState<boolean>(true)
  const [isWebhookSuccessful, setWebhookSuccessful] = useState<boolean>(false)

  const handleCloseDialog = () => {
    setOpenDialog(false)
    history.push("/home")
  }

  useEffect(() => {
    const runWebhookTrigger = async () => {
      setFetching(true)
      try {
        const response = await triggerWebhook(device.UUID)

        if (response === "ok") {
          setWebhookSuccessful(true)
        }
      } catch (error) {
        console.error("Error fetching QR image:", error)
      } finally {
        setFetching(false)
      }
    }

    runWebhookTrigger()

    return () => {
      setFetching(false)
      setWebhookSuccessful(false)
    }
  }, [customButtons, device])

  return (
    <Dialog
      open={isOpenDialog}
      setOpen={setOpenDialog}
      title={customButtons.title}
      onClose={handleCloseDialog}
      className="webhookDialog"
    >
      {isFetching ? (
        <div className="Loader">
          <LoaderSVG />
        </div>
      ) : isWebhookSuccessful ? (
        <div className="result">
          <CheckSVG />
          <div className="title">
            Request for <strong>{customButtons.title} succeeded</strong>
          </div>
        </div>
      ) : (
        <div className="result">
          <ErrorSVG />
          <div className="title">
            Request for <strong>{customButtons.title} failed</strong>
          </div>
        </div>
      )}
    </Dialog>
  )
}

export default Webhook
