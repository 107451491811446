import { fetchOptions } from "../../api/fetch"
import { loginURL } from "../../api/urls"
import { AppDispatch, RootState } from "../store"
import {
  FETCH_CSRF_ERROR,
  FETCH_CSRF_START,
  FETCH_CSRF_SUCCESS,
} from "./constants"

export const fetchCSRFStart = (UUID: string) => ({
  type: FETCH_CSRF_START,
  payload: { UUID },
})

export const fetchCSRFSuccess = (csrftoken: string) => ({
  type: FETCH_CSRF_SUCCESS,
  payload: csrftoken,
})

export const fetchCSRFError = (error: any) => ({
  type: FETCH_CSRF_ERROR,
  payload: error,
})

export const fetchCSRF =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
    } = getState()

    try {
      dispatch(fetchCSRFStart(UUID))
      const response = await fetch(loginURL(UUID), fetchOptions)
      const body = await response.text()

      const matchedCSRF = body.match(
        /<input type="hidden" name="csrfmiddlewaretoken" value="(.+)?">/,
      )

      const csrftoken = matchedCSRF ? matchedCSRF[1] : null

      if (csrftoken) {
        dispatch(fetchCSRFSuccess(csrftoken))
      } else {
        dispatch(fetchCSRFError(body))
      }

      return { csrftoken }
    } catch (e) {
      dispatch(fetchCSRFError(e))

      return {}
    }
  }
