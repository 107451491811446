import { ConstantsResponse, Featureset } from "./constants/types"

const JOAN_ON_TABLETS_INTERFACE = 8

export const interfaceConfigSelector = (constants: ConstantsResponse) => {
  if (!constants || !("featureset" in constants)) {
    return DEFAULT_CONFIG
  }

  const { featureset } = constants
  const { config } = featureset ?? {}
  const { interface: devInterface } = config ?? {}

  const interfaceConfig = devInterface
    ? devInterface[JOAN_ON_TABLETS_INTERFACE]
    : {}

  let jotConfig = {
    ...DEFAULT_CONFIG,
    ...defaultFeaturesetConfig(featureset),
    ...interfaceConfig,
  }

  return jotConfig
}

const defaultFeaturesetConfig = (featureset: Featureset) => {
  const {
    meetLater,
    meetNowTimeout: meetNowDuration,
    eventCancelation: cancelMeetings,
  } = featureset || {}

  return {
    meetLater,
    meetNowDuration,
    cancelMeetings: Number(cancelMeetings),
  }
}

export const DEFAULT_CONFIG = {
  meetNowDuration: 30,
  meetLater: true,
  cancelMeetings: 1,
  checkIntoMeetings: 0,
}
