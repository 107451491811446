import React, { ButtonHTMLAttributes, ReactNode, useRef } from "react"

import classNames from "classnames"

import {
  ANIMATION_OPTIONS,
  BUTTON_KEYFRAMES,
  ICON_BUTTON_KEYFRAMES,
} from "./constants"

import "./style.sass"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  className?: string
  isDisabled?: boolean
  isIconButton?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Button = ({
  children,
  className,
  isDisabled,
  isIconButton = false,
  onClick,
  ...buttonProps
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick?.(e)

    if (buttonRef.current && buttonRef.current.animate) {
      const animationKeyframes = isIconButton
        ? ICON_BUTTON_KEYFRAMES
        : BUTTON_KEYFRAMES
      buttonRef.current.animate(animationKeyframes, ANIMATION_OPTIONS)
    }
  }

  const buttonClassNames = classNames({
    Button: true,
    IconButton: isIconButton,
    [className || ""]: !!className,
    isDisabled,
  })

  return (
    <button
      {...buttonProps}
      className={buttonClassNames}
      onClick={handleClick}
      ref={buttonRef}
      disabled={isDisabled}
    >
      {children}
    </button>
  )
}

export default Button
