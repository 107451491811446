import React from "react"

import { formatTimeWithNumerals, getTime } from "@joan/joan-core"

type Props = {
  className?: string
  date: string | Date
  isInline?: boolean
  isLongDay?: boolean
}

const Datestring = ({
  className,
  date,
  isInline,
  isLongDay,
  ...props
}: Props) => {
  const DATE = getTime(date)

  const dayFormat = isLongDay ? "dddd" : "ddd"
  const format = isInline ? `${dayFormat}, Do MMM` : `${dayFormat} Do MMM`
  const formatedDate = formatTimeWithNumerals(DATE, format)

  return (
    <span className={className} {...props}>
      {formatedDate}
    </span>
  )
}

export default Datestring
