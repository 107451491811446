import { selectFeatureFlags } from "../redux/constants/selectors"
import { useAppSelector } from "../redux/store"

export const useCheckForFeatureFlag = (flagToFind?: string) => {
  const featureFlags = useAppSelector(selectFeatureFlags)

  // If flag is not specified, return as if it was enabled.
  // This is purely for convenience, so that we do not have to
  // do falsy checks when calling the function.
  if (!flagToFind) {
    return true
  }

  return !!featureFlags.find(
    (featureFlag: string) => flagToFind === featureFlag,
  )
}
