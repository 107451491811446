export const FETCH_CALENDAR_START = "FETCH_CALENDAR_START"
export const FETCH_CALENDAR_SUCCESS = "FETCH_CALENDAR_SUCCESS"
export const FETCH_CALENDAR_ERROR = "FETCH_CALENDAR_ERROR"

export const MEET_NOW_START = "MEET_NOW_START"
export const MEET_NOW_SUCCESS = "MEET_NOW_SUCCESS"
export const MEET_NOW_ERROR = "MEET_NOW_ERROR"

export const MEET_LATER_START = "MEET_LATER_START"
export const MEET_LATER_SUCCESS = "MEET_LATER_SUCCESS"
export const MEET_LATER_ERROR = "MEET_LATER_ERROR"

export const FINISH_MEETING_START = "FINISH_MEETING_START"
export const FINISH_MEETING_SUCCESS = "FINISH_MEETING_SUCCESS"
export const FINISH_MEETING_ERROR = "FINISH_MEETING_ERROR"

export const CANCEL_MEETING_START = "CANCEL_MEETING_START"
export const CANCEL_MEETING_SUCCESS = "CANCEL_MEETING_SUCCESS"
export const CANCEL_MEETING_ERROR = "CANCEL_MEETING_ERROR"

export const CHECK_IN_START = "CHECK_IN_START"
export const CHECK_IN_SUCCESS = "CHECK_IN_SUCCESS"
export const CHECK_IN_ERROR = "CHECK_IN_ERROR"

// TODO: Get meetNow duration from constants
export const MEET_NOW_DURATION = 60
