import { getTimeNow } from "../../utils"
import { getTime } from "@joan/joan-core"

const few = (n = 6) => Math.floor(Math.random() * n)

const shuffle = () => Math.floor(Math.random() * 2) - 1

const roomNames = [
  "Narnia",
  "Oz",
  "Neverland",
  "Wonderland",
  "Atlantis",
  "Galaxy",
  "Camelot",
  "Hogwarts",
  "Holodeck",
  "Ocean",
  "Mars",
  "Aquarium",
  "Balcony",
].sort(shuffle)

export function getRoomName() {
  return roomNames[Math.floor(Math.random() * roomNames.length)]
  // return roomNames.pop()
}

const randomLetter = () => {
  const letters = "QWERTUIPASDFGHJKLZCVBNM"
  return letters[Math.floor(Math.random() * letters.length)]
}

const personNames = [
  `Anna ${randomLetter()}.`,
  `Greg ${randomLetter()}.`,
  `Megan ${randomLetter()}.`,
  `Dan ${randomLetter()}.`,
  `Sonya ${randomLetter()}.`,
  `Sebastian ${randomLetter()}.`,
  `Simon ${randomLetter()}.`,
  `Matt ${randomLetter()}.`,
  `Vicky ${randomLetter()}.`,
  `Jessica ${randomLetter()}.`,
  `David ${randomLetter()}.`,
  `Michelle ${randomLetter()}.`,
].sort(shuffle)

function getPerson() {
  const displayName =
    personNames[Math.floor(Math.random() * personNames.length)]
  return { displayName, email: "user@domain.com" }
  // return personNames.pop()
}

function getAttendees(count) {
  const attendees = []
  for (let i = 0; i < count; i++) {
    attendees.push(getPerson())
  }
  return attendees
}

const summaries = [
  "Product team sync",
  "Quiet working",
  "Telco with Mark",
  "Design review",
  "Social content creation",
  "Growth weekly",
  "Cost analysis",
  "Product team sync",
  "Growth weekly",
  "Copywriting",
  "Sales meeting",
  "Stock planning",
].sort(shuffle)

function getSummary() {
  return summaries[Math.floor(Math.random() * summaries.length)]
  // return summaries.pop()
}

let id = 0
function getId() {
  return id++
}
const randomEventData = (hoursLater = 1) => {
  const start = getTimeNow()
    .minute(0)
    .add(hoursLater, "hour")
    .add((1 + few()) * 15, "minutes")
    .toISOString()
  const end = getTime(start)
    .add((1 + few(2)) * 15, "minutes")
    .toISOString()

  const resource = getRoomName()
  const organizer = getPerson()
  const summary = getSummary()

  const attendees = getAttendees(1 + few(10))
  return {
    id: getId(),
    resource,
    resourceFloor: Math.random() > 0.3 ? `L${1 + few(2)}` : "L1",
    summary,
    organizer,
    attendees,
    start: start,
    end: end,
  }
}

const dupliProgressEventData = {
  ...randomEventData(),
  summary: "Meeting conference",
  start: getTimeNow()
    .minute(Math.floor(getTime().minute() / 5) * 5)
    .toISOString(),
}

const comingUpEventData1 = {
  ...randomEventData(),
  start: getTime().add(0.75, "hours").toISOString(),
}

const laterEventData1 = {
  ...randomEventData(),
  start: getTime().minute(0).add(4, "hours").toISOString(),
}

const eventComparison = (a, b) => {
  return new Date(a.start) - new Date(b.start)
}

export const events = [
  dupliProgressEventData,
  comingUpEventData1,
  laterEventData1,
  randomEventData(),
  randomEventData(),
  randomEventData(2),
  randomEventData(2),
  randomEventData(5),
  randomEventData(5),
  randomEventData(5),
  randomEventData(12),
  randomEventData(12),
  randomEventData(12),
  randomEventData(26 - 12),
  randomEventData(26 - 12),
  randomEventData(26 - 12),
  randomEventData(28 - 12),
  randomEventData(20 + 12),
  randomEventData(22 + 12),
  randomEventData(24 + 12),
  randomEventData(26 + 12),
]
  .filter((e) => typeof e !== "number")
  .sort(eventComparison)

const randomVacancyData = () => {
  const nextstart = getTimeNow()
    .minute(Math.ceil(getTimeNow().minute() / 15) * 15)
    .add((1 + few()) * 15, "minutes")
    .toISOString()

  return {
    resource: roomNames.pop(),
    resourceFloor: `L${1 + few(2)}`,
    nextstart: nextstart,
    capacity: 2 + few(10) * 2,
  }
}

const vacancyComparison = (a, b) =>
  new Date(a.nextstart) - new Date(b.nextstart)

export const vacancies = [
  randomVacancyData(),
  randomVacancyData(),
  randomVacancyData(),
  randomVacancyData(),
].sort(vacancyComparison)
