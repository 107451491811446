import React from "react"

import { Trans } from "@joan/joan-core"

const NotFound = () => (
  <div>
    <Trans>404 NotFound</Trans>
  </div>
)

export default NotFound
