import React, { Fragment } from "react"

import classNames from "classnames"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { getTimeNow, isConfirmedEvent, nameOrEmail } from "../../utils"
import Amenities from "../Amenities"
import Attendees from "../Attendees"
import Button from "../Button"
import Datestring from "../Datestring"
import Duration from "../Duration"
import MeetingTitle from "../MeetingTitle"
import Organizer from "../Organizer"
import StatusBar from "../StatusBar"
import Timestamp from "../Timestamp"
import { getTime, t, Trans } from "@joan/joan-core"

import { cancelMeetingDemo, finishMeetingDemo } from "../../redux/demoActions"
import {
  cancelMeeting,
  checkInAndFetchCalendar,
  finishMeeting,
} from "../../redux/events/actions"
import { useAppSelector } from "../../redux/store"

import CheckSVG from "../../assets/icons/check.svg"
import CloseSVG from "../../assets/icons/close.svg"

import "./style.sass"

const IS_DEMO = !!import.meta.env.VITE_APP_IS_DEMO || false

// BASE CARD
const Card = ({ className, children, actions, forwardedRef, ...props }) => {
  const cardClassNames = classNames({
    Card: true,
    [className]: !!className,
  })

  return (
    <div className={cardClassNames} ref={forwardedRef} {...props}>
      {children}
      <div className="actions">{actions}</div>
    </div>
  )
}

export default Card

// ICON BUTTONS
const CloseIconButton = ({ label, onClick, isDisabled }) => (
  <Button
    aria-label={label}
    className={"Cancel"}
    onClick={onClick}
    isDisabled={isDisabled}
    isIconButton
  >
    <CloseSVG />
  </Button>
)

const CheckIconButton = ({ label, onClick, isDisabled }) => (
  <Button
    aria-label={label}
    className={"Confirm"}
    onClick={onClick}
    isDisabled={isDisabled}
    isIconButton
  >
    <CheckSVG />
  </Button>
)

// CURRENT MEETING
export const CurrentMeetingCard = ({
  id,
  roomName,
  resource,
  summary,
  organizer,
  roomProperties,
  attendees = [],
  start,
  end,
  showStatusBar = false,
  showControls = false,
  showAmenities = false,
  checkInAndFetchCalendar,
  finishMeeting,
  isCheckInLoading,
  isMeetNowLoading,
  isFinishMeetingLoading,
  constants,
  canCheckIntoMeetings,
  checkIntoMeetingsTimeout,
  canCancelMeetings,
}) => {
  const meetingAttendees = attendees.filter((a) => a.mail !== organizer.email)
  const now = getTimeNow()
  const startDate = getTime(start)
  const endDate = getTime(end)

  const isVacant = !(now.isAfter(startDate) && now.isBefore(endDate))
  const isNextMeeting = start && now.isBefore(startDate)
  const isNextMeetingInTheFuture = now.day() < endDate.day()
  const isNextMeetingTomorrow = now.day() + 1 === endDate.day()
  const isNextMeetingWithinWeek = now.day() + 6 > endDate.day()

  const areActionsLoading =
    isCheckInLoading || isFinishMeetingLoading || isMeetNowLoading

  const minutesAfterStart = now.diff(startDate, "minutes", true)

  const isConfirmable =
    checkIntoMeetingsTimeout > minutesAfterStart && !areActionsLoading

  const isCancellable = canCancelMeetings && !areActionsLoading

  const meetingTitle = !isVacant ? summary || t("Occupied") : t("Vacant")

  const currentMeetingClassNames = classNames({
    CurrentMeeting: true,
    CurrentMeetingCard: !isVacant,
    VacantCard: isVacant,
  })

  return (
    <Card
      className={currentMeetingClassNames}
      actions={
        !isVacant &&
        showControls && (
          <Fragment>
            {canCancelMeetings && (
              <CloseIconButton
                label={t("Cancel")}
                onClick={() => finishMeeting(id)}
                isDisabled={!isCancellable}
              />
            )}
            {canCheckIntoMeetings && (
              <CheckIconButton
                label={t("Confirm")}
                onClick={() => checkInAndFetchCalendar(id, endDate)}
                isDisabled={!isConfirmable}
              />
            )}
          </Fragment>
        )
      }
    >
      <p className="room-name">{roomName}</p>
      {showAmenities && <Amenities roomProperties={roomProperties} />}
      <div className="meeting-info">
        <MeetingTitle>{meetingTitle}</MeetingTitle>

        {!isVacant && <Organizer organizer={organizer} resource={resource} />}
        {!isVacant && meetingAttendees.length > 0 && (
          <Attendees attendees={meetingAttendees} />
        )}
      </div>
      {(!isVacant || isNextMeeting) && (
        <Duration>
          <span>{t("Until")} </span>
          {isNextMeetingInTheFuture ? (
            <Fragment>
              <span> </span>
              {isNextMeetingTomorrow ? (
                <span>{t("tomorrow")}</span>
              ) : isNextMeetingWithinWeek ? (
                <span>{endDate.format("dddd")}</span>
              ) : (
                <Datestring date={end} isInline />
              )}
              <span>, </span>
            </Fragment>
          ) : (
            <span> </span>
          )}
          <Timestamp time={isNextMeeting ? start : end} isInline />
        </Duration>
      )}
      {showStatusBar && (
        <StatusBar
          className="meeting-status"
          isTaken={!isVacant}
          start={start}
          end={end}
        />
      )}
    </Card>
  )
}

const currentMeetingRedux = [
  ({ checkIn, finishMeeting, meetNow, constants }) => ({
    isCheckInLoading: checkIn.isLoading,
    isFinishMeetingLoading: finishMeeting.isLoading,
    isMeetNowLoading: meetNow.isLoading,
    constants: constants,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        checkInAndFetchCalendar: (id, end) => checkInAndFetchCalendar(id, end),
        finishMeeting: (id) =>
          IS_DEMO ? finishMeetingDemo(id) : finishMeeting(id),
      },
      dispatch,
    ),
]

export const DecoratedCurrentMeeting = connect(...currentMeetingRedux)(
  CurrentMeetingCard,
)

// NEXT MEETING
export const NextMeetingCard = ({
  id,
  resource,
  summary,
  description,
  organizer = {},
  attendees = [],
  start,
  end,
  showControls = false,
  forwardedRef,
  checkInAndFetchCalendar,
  cancelMeeting,
  canCheckIntoMeetings,
  canCancelMeetings,
  checkIntoMeetingsTimeout,
  isCheckInLoading,
  isCancelMeetingLoading,
  isMeetLaterLoading,
}) => {
  const { confirmed: confirmedEventsIds } = useAppSelector(
    (state) => state.events,
  )

  const now = getTimeNow()
  const isNextMeeting = start && end
  const startDate = getTime(start)
  const endDate = getTime(end)
  const isMeetingInTheFuture = now.day() < startDate.day()
  const isMeetingTomorrow = now.day() + 1 === startDate.day()
  const isMeetingWithinWeek = now.day() + 6 > startDate.day()
  const meetingAttendees = attendees.filter((a) => a.mail !== organizer.email)

  const nextMeetingClassNames = classNames({
    NextMeetingCard: true,
    NoMeetingsCard: !isNextMeeting,
  })

  const isOrganizedByResource = organizer.email === resource

  const areActionsLoading =
    isCheckInLoading || isCancelMeetingLoading || isMeetLaterLoading

  const minutesBeforeStart = startDate.diff(now, "minutes", true)

  const isConfirmable =
    !isConfirmedEvent(id, confirmedEventsIds) &&
    checkIntoMeetingsTimeout > minutesBeforeStart &&
    !areActionsLoading

  const isCancellable = canCancelMeetings && !areActionsLoading

  return (
    <Card
      forwardedRef={forwardedRef}
      className={nextMeetingClassNames}
      actions={
        isNextMeeting &&
        showControls && (
          <Fragment>
            {canCancelMeetings && (
              <CloseIconButton
                label={t("Cancel")}
                onClick={() => cancelMeeting(id)}
                isDisabled={!isCancellable}
              />
            )}
            {canCheckIntoMeetings && (
              <CheckIconButton
                label={t("Confirm")}
                onClick={() => checkInAndFetchCalendar(id, endDate)}
                isDisabled={!isConfirmable}
              />
            )}
          </Fragment>
        )
      }
    >
      {isNextMeeting && (
        <h2 className="meeting-title">
          {summary || description || t("Booked")}
        </h2>
      )}
      {<Organizer organizer={organizer} resource={resource} showIcon={false} />}
      {meetingAttendees.length === 1 ? (
        <Attendees showIcon={false}>
          {!isOrganizedByResource && <span>&nbsp;&amp;</span>}{" "}
          {nameOrEmail(meetingAttendees[0])}
        </Attendees>
      ) : (
        meetingAttendees.length > 1 && (
          <Attendees showIcon={false}>
            , {meetingAttendees.length} {t("attendees")}
          </Attendees>
        )
      )}
      {isNextMeeting ? (
        <Duration>
          {isMeetingInTheFuture && (
            <Fragment>
              {isMeetingTomorrow ? (
                <span>
                  <Trans>tomorrow</Trans>
                </span>
              ) : isMeetingWithinWeek ? (
                <span>{startDate.format("dddd")}</span>
              ) : (
                <Datestring date={start} isInline isLongDay />
              )}
              ,{` `}
            </Fragment>
          )}
          <Timestamp time={start} isInline /> &ndash;{" "}
          <Timestamp time={end} isInline />
        </Duration>
      ) : (
        <p>
          <Trans>No meetings scheduled for today.</Trans>
        </p>
      )}
    </Card>
  )
}

const nextMeetingRedux = [
  ({ checkIn, cancelMeeting, meetLater }) => ({
    isCheckInLoading: checkIn.isLoading,
    isCancelMeetingLoading: cancelMeeting.isLoading,
    isMeetLaterLoading: meetLater.isLoading,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        checkInAndFetchCalendar: (id, end) => checkInAndFetchCalendar(id, end),
        cancelMeeting: (id) =>
          IS_DEMO ? cancelMeetingDemo(id) : cancelMeeting(id),
      },
      dispatch,
    ),
]

export const DecoratedNextMeeting = connect(...nextMeetingRedux)(
  NextMeetingCard,
)
