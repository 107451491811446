import React, { Dispatch, ReactNode, SetStateAction, useEffect } from "react"

import classNames from "classnames"
import { useHistory } from "react-router-dom"

import { NAVIGATE_HOME_TIMEOUT } from "../../constants"
import { PATHS } from "../../constants"

import { fetchConstants } from "../../redux/constants/actions"
import { fetchCalendar } from "../../redux/events/actions"
import { useAppDispatch, useAppSelector } from "../../redux/store"

import CloseSVG from "../../assets/icons/close.svg"
import LogoHorizontalSVG from "../../assets/joan-negative-horiz.svg"
import LogoVerticalSVG from "../../assets/joan-vertical.svg"

import "./style.sass"

type Props = {
  children: ReactNode
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  autoConfirm?: boolean
  onClose?: () => void
  title: string
  className?: string
}

const Dialog = ({
  children,
  open,
  setOpen,
  autoConfirm = true,
  onClose,
  title,
  className,
}: Props) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { logo, logoObjectURL } = useAppSelector((state) => state.constants)

  useEffect(() => {
    if (autoConfirm && open) {
      const autoConfirmTimeout = setTimeout(() => {
        onClose?.()
      }, NAVIGATE_HOME_TIMEOUT)

      return () => {
        autoConfirmTimeout && clearTimeout(autoConfirmTimeout)
      }
    }
  }, [autoConfirm, onClose, open, setOpen])

  const handleCloseDialogClick = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  const handleLogoClick = () => {
    dispatch(fetchCalendar())
    dispatch(fetchConstants())
    history.push(PATHS.HOME)
  }

  if (!open) {
    return null
  }

  return (
    <div
      className={classNames({
        Dialog: true,
        isAutoConfirm: autoConfirm,
        [className ?? ""]: true,
      })}
    >
      <div className="DialogContainer">
        <div className="DialogContent">
          <div className="DialogContentHead">
            <div className="Logo" onClick={handleLogoClick}>
              {!!logo && !!logoObjectURL ? (
                <img alt="logo" src={logoObjectURL} />
              ) : (
                <LogoHorizontalSVG />
              )}
            </div>
            <div>{title}</div>
          </div>
          <div className="DialogContentBody">{children}</div>
          <div className="DialogContentFoot">
            {autoConfirm && (
              <div className="DialogAutoconfirm">
                <div>This screen will auto-dismiss in 30 sec.</div>
              </div>
            )}
            <div className="DialogCloseButton" onClick={handleCloseDialogClick}>
              <CloseSVG />
            </div>
          </div>
        </div>
        <div className="DialogAside">
          <div className="Logo" onClick={handleLogoClick}>
            {!!logo && !!logoObjectURL ? (
              <img alt="logo" src={logoObjectURL} />
            ) : (
              <LogoVerticalSVG />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dialog
