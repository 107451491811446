import React from "react"

import classNames from "classnames"

import { ISODate } from "../../types/sharedTypes"
import { formatTimeWithNumerals, getTime } from "@joan/joan-core"

import { useAppSelector } from "../../redux/store"

import "./style.sass"

type Props = {
  time: ISODate | Date
  showSeconds?: boolean
  isInline?: boolean
  leadingZero?: boolean
  className?: string
}

const Timestamp = ({
  time,
  showSeconds = false,
  isInline = false,
  leadingZero = false,
  className,
}: Props) => {
  const DATE = getTime(time)

  const showAMPM = useAppSelector((state) => state.constants.ampm === 1)

  const timestampClassName = classNames({
    Timestamp: true,
    [className || ""]: !!className,
    isInline,
    showSeconds,
    showAMPM,
  })

  const h = showAMPM ? "h" : "H"
  const hh = h + h

  const timeFormat = leadingZero ? `${hh}:mm` : `${h}:mm`

  return (
    <span className={timestampClassName}>
      <span className="hours minutes">
        {formatTimeWithNumerals(DATE, timeFormat)}
      </span>
      {showSeconds && (
        <span className="seconds">{formatTimeWithNumerals(DATE, ":ss")}</span>
      )}
      {showAMPM && (
        <span className="am-pm"> {formatTimeWithNumerals(DATE, "A")}</span>
      )}
    </span>
  )
}

export default Timestamp
