import { RootState } from "../store"

export const selectEvents = (state: RootState) => state.events

export const selectCurrentEvent = (state: RootState) =>
  state.events.currentEvent

export const selectConfirmedEvents = (state: RootState) =>
  state.events.confirmed

export const selectIsLoadedEvents = (state: RootState) => state.events.isLoaded
