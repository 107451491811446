import React, { ReactNode } from "react"

import "./style.sass"

type Props = {
  children: ReactNode
}

const InlineSVG = ({ children }: Props) => (
  <span className="InlineSVG">{children}</span>
)

export default InlineSVG
