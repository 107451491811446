export const ACTIONS_TYPES = {
  QRCODE: 1,
  WEBHOOK: 2,
} as const

export type ActionTypes = (typeof ACTIONS_TYPES)[keyof typeof ACTIONS_TYPES]

export const REPLACES = {
  MEET_NOW: 1,
  MEET_LATER: 2,
  TIMETABLE: 3,
} as const

export type ReplacesTypes = (typeof REPLACES)[keyof typeof REPLACES]
