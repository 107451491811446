import React, { useCallback, useEffect, useRef, useState } from "react"

import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { PATHS } from "../../constants"
import {
  eventConflicts,
  filterOptimistic,
  getTimeNow,
  orderByDate,
  zeroMinutes,
} from "../../utils"
import { availabilitySlots, availableDurations, groupItemsByDay } from "./utils"
import { getOrdinalFromNumber, getTime, t, Trans } from "@joan/joan-core"

import { meetLaterDemo } from "../../redux/demoActions"
import { meetLater } from "../../redux/events/actions"
import { interfaceConfigSelector } from "../../redux/selectors"
import { confirmActionSuccess } from "../../redux/ui/actions"

import Button from "../../components/Button"
import GridSelect from "../../components/GridSelect"

import CheckSVG from "../../assets/icons/check.svg"

import "./style.sass"

const IS_DEMO = !!import.meta.env.VITE_APP_IS_DEMO || false

const CheckIconButton = ({ label, onClick, isDisabled }) => (
  <Button
    aria-label={label}
    className={"Confirm"}
    onClick={onClick}
    isDisabled={isDisabled}
    isIconButton
  >
    <CheckSVG />
  </Button>
)

const MeetLater = () => {
  const [title, setTitle] = useState(t("Occupied"))
  const [isTitleFocused, setIsTitleFocused] = useState(false)
  const [slotValue, setSlotValue] = useState(null)
  const [durationValue, setDurationValue] = useState(null)
  const [slotCount, setSlotCount] = useState(15)
  const [areSlotsExpanded, setAreSlotsExpanded] = useState(false)
  const [areDurationsExpanded, setAreDurationsExpanded] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const events = useSelector((state) =>
    orderByDate(filterOptimistic(state.events.events)),
  )
  const config = useSelector((state) =>
    interfaceConfigSelector(state.constants),
  )
  const confirmAction = useSelector((state) => state.ui.confirmAction)

  const prevConfirmActionRef = useRef(confirmAction)

  const handleConfirmClick = useCallback(async () => {
    const now = getTimeNow()
    const expiredSlots = Math.floor(now.minute() / 15)

    const currentSlot = zeroMinutes(now)
      .add(expiredSlots * 15, "minute")
      .valueOf()

    const slots = availabilitySlots(
      events,
      now,
      currentSlot,
      slotCount,
      expiredSlots,
    )

    const nextAvailableSlot = slots.find(({ value, isDisabled }) => {
      const doesConflict = eventConflicts(events, value)
      return !doesConflict && !isDisabled
    })

    const initialSlot = eventConflicts(events, now)
      ? nextAvailableSlot
        ? nextAvailableSlot.value
        : null
      : currentSlot

    const start = getTime(slotValue || initialSlot)
    const end = start.add(durationValue || 15, "minute")

    history.push(PATHS.HOME)

    if (start.isValid() && end.isValid()) {
      await (IS_DEMO
        ? dispatch(meetLaterDemo(start.isBefore(now) ? now : start, end, title))
        : dispatch(meetLater(start.isBefore(now) ? now : start, end, title)))
      await dispatch(confirmActionSuccess())
    }
  }, [dispatch, events, history, slotValue, durationValue, slotCount, title])

  useEffect(() => {
    if (!config.meetLater) {
      history.push(PATHS.HOME)
    }

    if (!confirmAction) return

    if (prevConfirmActionRef?.current === false && confirmAction === true) {
      handleConfirmClick()
    }

    prevConfirmActionRef.current = confirmAction
  }, [config.meetLater, confirmAction, handleConfirmClick, history])

  const now = getTimeNow()
  const expiredSlots = Math.floor(now.minute() / 15)

  const currentSlot = zeroMinutes(now)
    .add(expiredSlots * 15, "minute")
    .valueOf()

  const slots = availabilitySlots(
    events,
    now,
    currentSlot,
    slotCount,
    expiredSlots,
  )

  const durations = availableDurations(
    events,
    slotValue,
    areDurationsExpanded ? 16 : 3,
  )

  const nextAvailableSlot = slots.find(({ value, isDisabled }) => {
    const doesConflict = eventConflicts(events, value)
    return !doesConflict && !isDisabled
  })

  const initialSlot = eventConflicts(events, now)
    ? nextAvailableSlot
      ? nextAvailableSlot.value
      : null
    : currentSlot

  const initialDuration = durations[0].value
  const selectedSlot = slotValue || initialSlot
  const selectedDuration = durationValue || initialDuration
  const bookingMonth = getTime(selectedSlot).format("MMM")
  const bookingDate = getOrdinalFromNumber(getTime(selectedSlot).date())
  const bookingDay = `${bookingMonth} ${bookingDate}`

  const meetLaterClassName = classNames({
    MeetLater: true,
    Route: true,
    isTitleFocused,
  })

  return (
    <div className={meetLaterClassName}>
      <h2>
        {t("New meeting")}: {bookingDay}
      </h2>

      <GridSelect
        items={slots}
        value={selectedSlot}
        reduceFunc={groupItemsByDay}
        onChange={setSlotValue}
        onExpand={() => {
          setSlotCount((prev) => prev + 32)
          setAreSlotsExpanded(true)
        }}
        isExpanded={areSlotsExpanded}
        showExpander
        isHorizontal={isTitleFocused}
      />

      <GridSelect
        items={durations}
        value={selectedDuration}
        onChange={setDurationValue}
        onExpand={() => setAreDurationsExpanded(true)}
        isExpanded={areDurationsExpanded}
        showExpander={!areDurationsExpanded}
        isHorizontal
      />

      <div className="actions">
        <div className="title">
          <label htmlFor="title">
            <span>
              <Trans>Title</Trans>
            </span>
          </label>
          <input
            id="title"
            name="title"
            value={title}
            autoComplete="off"
            onChange={(e) => setTitle(e.currentTarget.value)}
            onFocus={() => setIsTitleFocused(true)}
            onBlur={() => {
              setTimeout(() => setIsTitleFocused(false), 200)
            }}
          />
        </div>
        <CheckIconButton onClick={handleConfirmClick} />
      </div>
    </div>
  )
}

export default MeetLater
