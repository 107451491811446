import React, { useCallback, useEffect } from "react"

import { getTimeNow, isConfirmedEvent } from "../../utils"
import { getTime } from "@joan/joan-core"

import { recalculateEvents } from "../../redux/events/actions"
import {
  selectConfirmedEvents,
  selectEvents,
} from "../../redux/events/selectors"
import { ExtendedEvent } from "../../redux/events/types"
import { interfaceConfigSelector } from "../../redux/selectors"
import { useAppDispatch, useAppSelector } from "../../redux/store"

import {
  DecoratedCurrentMeeting as CurrentMeetingCard,
  DecoratedNextMeeting as NextMeetingCard,
} from "../../components/Card"

import "./style.sass"

const Home = () => {
  const dispatch = useAppDispatch()

  const constants = useAppSelector((state) => state.constants)
  const config = useAppSelector((state) =>
    interfaceConfigSelector(state.constants),
  )

  const events = useAppSelector(selectEvents)
  const confirmedEvents = useAppSelector(selectConfirmedEvents)
  const currentMeeting = events.currentEvent
  const nextMeeting = events.nextEvent

  const isBookingDisabled =
    constants.calendar && constants.calendar.indexOf("http") === 0

  const recalcEventsIfNeeded = useCallback(() => {
    if (currentMeeting) {
      const now = getTimeNow()
      const endDate = getTime(currentMeeting.end)

      if (endDate.isBefore(now)) {
        dispatch(recalculateEvents())
      }
    }
  }, [currentMeeting, dispatch])

  useEffect(() => {
    recalcEventsIfNeeded()
    const intervalId = setInterval(recalcEventsIfNeeded, 1000)
    return () => clearInterval(intervalId)
  }, [recalcEventsIfNeeded])

  const canCancelMeeting = (meeting: ExtendedEvent | null) => {
    switch (config.cancelMeetings) {
      case 0:
        return false
      case 1:
        return true
      case 2:
        return (
          !!meeting &&
          (meeting.isMeetNow ||
            meeting.isMeetLater ||
            meeting.creator.self ||
            meeting.on_spot)
        )
      default:
        return true
    }
  }

  const canCheckIntoMeetings = (event: ExtendedEvent) =>
    !!checkIntoMeetings &&
    !isBookingDisabled &&
    !isConfirmedEvent(event?.id, confirmedEvents)

  const { name = "", room_properties } = constants
  const { checkIntoMeetings } = config

  return (
    <div className="Home Route" key="home-route">
      <CurrentMeetingCard
        roomName={name}
        {...(currentMeeting || nextMeeting)}
        roomProperties={room_properties}
        canCancelMeetings={
          canCancelMeeting(currentMeeting) && !isBookingDisabled
        }
        canCheckIntoMeetings={canCheckIntoMeetings(
          currentMeeting || nextMeeting,
        )}
        checkIntoMeetingsTimeout={checkIntoMeetings}
        showAmenities
        showControls
        showStatusBar
      />

      <NextMeetingCard
        {...nextMeeting}
        canCancelMeetings={canCancelMeeting(nextMeeting) && !isBookingDisabled}
        canCheckIntoMeetings={canCheckIntoMeetings(nextMeeting)}
        checkIntoMeetingsTimeout={checkIntoMeetings}
        showControls
      />
    </div>
  )
}

export default Home
