import React, { useEffect, useState } from "react"

import classNames from "classnames"
import { Route } from "react-router-dom"

import AnimatedRouter from "./AnimatedRouter"
import { PATHS } from "./constants"
import Onboarding from "./Onboarding"
import ProDvxProvider from "./providers/ProDvxProvider"
import Home from "./routes/Home"
import { PWA as PWAInstructions } from "./routes/Instructions"
import MeetLater from "./routes/MeetLater"
import NotFound from "./routes/NotFound"
import QRCode from "./routes/QRCode"
import Timetable from "./routes/Timetable"
import Webhook from "./routes/Webhook"
import {
  isInDevelopment,
  isIPad,
  isPWAinBrowser,
  isQRCodeCustomButton,
  isWebHookCustomButton,
  orientationState,
} from "./utils"
import { langDirection, setLocale, TransProvider } from "@joan/joan-core"
import { TLocale } from "@joan/joan-core/dist/interfaces/Locale.interface"

import { useAppSelector } from "./redux/store"

import Loading from "./components/Loading"
import PaymentNotification from "./components/PaymentNotification"

import "./App.sass"

const BETA_PERIOD = true
const IS_DEMO = import.meta.env.VITE_APP_IS_DEMO || false

const JoanOnTablets = () => {
  const [orientation, setOrientation] = useState(orientationState())

  const handleResize = () => {
    setOrientation(orientationState())
  }

  const { constants = {} } = useAppSelector((state) => ({
    constants: state.constants,
  }))
  const { customButtons } = constants

  const customButtonAction = customButtons ? customButtons.action : null

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const setLanguageToHtmlTag = ({
    language,
    direction,
  }: {
    language: string
    direction: string
  }) => {
    const root = document.getElementsByTagName("html")[0]

    if (language) root?.setAttribute("lang", language)
    if (direction) root?.setAttribute("dir", direction)
  }

  const handleTranslationChange = (lang: TLocale) => {
    if (!lang) {
      console.warn("Desired language is empty! Skipping setLocale.")
      return
    }

    const direction = langDirection(lang)
    setLanguageToHtmlTag({ language: lang, direction })

    // Language fallback hack
    if (lang.toLowerCase() === "en-us") {
      lang = "en-gb"
    }

    setLocale(lang)
  }

  const { isPortrait, isLandscape } = orientation

  const {
    warning_l2,
    logo = "",
    locale,
  } = useAppSelector((state) => state.constants)

  const appClassName = classNames({
    App: true,
    iPad: isIPad(),
    isPortrait,
    isLandscape,
  })

  const loadingClassName = classNames({
    Loading: true,
  })

  if (warning_l2) {
    return (
      <div className={appClassName}>
        <PaymentNotification />
      </div>
    )
  }

  if (isPWAinBrowser() && !isInDevelopment() && !BETA_PERIOD) {
    return (
      <div className={appClassName}>
        <PWAInstructions />
      </div>
    )
  }

  return (
    <div className={appClassName}>
      <TransProvider
        lang={locale}
        catalog="tablet"
        onChange={handleTranslationChange}
        loader={<Loading className={loadingClassName} />}
      >
        <Onboarding>
          <AnimatedRouter logo={logo}>
            <Route path={PATHS.HOME} exact component={Home} />
            <Route path={PATHS.MEET_LATER} exact component={MeetLater} />
            <Route path={PATHS.TIMETABLE} exact component={Timetable} />
            {isQRCodeCustomButton(customButtonAction) && (
              <Route path={PATHS.QR_CODE} exact component={QRCode} />
            )}
            {isWebHookCustomButton(customButtonAction) && (
              <Route path={PATHS.WEB_HOOK} exact component={Webhook} />
            )}
            {!IS_DEMO && <Route path="*" component={NotFound} />}
          </AnimatedRouter>
        </Onboarding>
        <ProDvxProvider />
      </TransProvider>
    </div>
  )
}

export default JoanOnTablets
