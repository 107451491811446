import { AnyAction } from "redux"

import { FETCH_AUTH_ERROR, FETCH_AUTH_SUCCESS } from "../auth/constants"
import { FETCH_CSRF_SUCCESS } from "./constants"

const initialDeviceState = {
  UUID: null,
  csrftoken: null,
  authenticated: false,
}

const deviceReducer = (state = initialDeviceState, action: AnyAction) => {
  const { type, payload } = action

  switch (type) {
    case "GENERATE_UUID_SUCCESS":
      return {
        ...state,
        UUID: payload,
      }

    case FETCH_CSRF_SUCCESS:
      return {
        ...state,
        csrftoken: payload,
      }

    case FETCH_AUTH_SUCCESS:
      const becameAuthenticated =
        state.authenticated === false && payload.authenticated === true

      return {
        ...state,
        authenticated: payload.authenticated,
        csrftoken: becameAuthenticated ? null : state.csrftoken,
      }

    case FETCH_AUTH_ERROR:
      return {
        ...state,
        csrftoken: null,
      }

    default:
      return state
  }
}

export default deviceReducer
