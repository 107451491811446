import { RootState } from "../store"

export const selectProDvxToken = (state: RootState) =>
  state.constants?.prodvx?.access_token ?? null

export const selectFeatureset = (state: RootState) =>
  state.constants?.featureset ?? null

export const selectFeatureFlags = (state: RootState) =>
  state.constants?.featureFlags ?? []
